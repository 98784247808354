.card {
  .content {
    width: calc(100% - 55px);
    height: 100%;
  }

  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  // box-shadow: 0 0 10px #0000002a;
  @apply bg-white rounded-lg flex items-center;
}

.heading {
  font-size: 50px;
  background: -webkit-linear-gradient(#00bac7, #679046);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 60px;

  @media (max-width: 767px) {
    font-size: 33px;
    line-height: 40px;
  }
}

.heading2 {
  font-size: 30px;
  background: -webkit-linear-gradient(#00bac7, #679046);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 40px;

  @media (max-width: 767px) {
    font-size: 23px;
    line-height: 30px;
  }
}

.paragraph {
  @apply text-xl  text-textColor;

  @media (max-width: 767px) {
    @apply text-sm;
  }
}

.step-count {
  @apply text-fountainBlue font-semibold uppercase md:text-2xl text-xl pb-1;
}

.step-question {
  @apply text-dimGray md:text-3xl text-xl font-semibold pb-5;
}

.ant-picker-body {
    padding: 4px 18px !important;
  .ant-picker-dropdown {
    .ant-picker-content {
      td {
        padding: 2px 0 !important;
      }
    }
  }

}

.ant-picker-dropdown {
    .ant-picker-cell{
        padding: 2px 0 !important;
    }
  }
