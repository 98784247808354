.primary-btn {
    @apply rounded h-max bg-fountainBlue border-fountainBlue text-sm text-whiteSmoke font-semibold;
}

.secondary{
  @apply  bg-white text-fountainBlue border-fountainBlue;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}