.ant-modal {
    width: 800px !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;

    .ant-modal-footer {
        @apply hidden
    }

    .ant-modal-close {
        @apply hidden
    }

    .ant-modal-content {
        @apply p-0 rounded-xl
    }

    .ant-modal-body {
        @apply rounded-xl
    }

    .button.secondary {
        width: 85%;
        @apply mx-auto text-center;
    }
}

.enrolment-modal-image {
    background-image: url(../../assets/images/enrolmentModalImage.png);
    width: 55%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @apply rounded-tr-xl rounded-br-xl;
    @media (max-width: 767px) {
        display: none;
    }
}

.modal-left-content {
    width: 45%;
    @media (max-width: 767px) {
        width: 100%;
    }
}
