.header {
    box-shadow: 0 0 10px #0000002a;
    height: 90px;
    @apply flex items-center bg-white justify-between fixed top-0 w-screen z-50;

    .logo {
        width: 250px;

        img {
            max-width: 100%;
        }
    }

    .toggleIcon {
        @apply hidden w-5 fill-fountainBlue;

        @media (max-width: 991px) {
            @apply block;
        }
    }

    .right-nav {
        @apply flex items-center justify-end transition-transform duration-700;

        @media (max-width: 991px) {
            height: calc(100vh - 90px);
            width: 200px;
            top: 90px;
            @apply absolute right-0 bg-white items-start justify-start pt-8 pl-4 top-[90px] transition-all duration-700;

            .navigation-list {
                align-items: start !important;
                @apply flex-col;
            }
        }

        .navigation-list {
            @apply flex list-none gap-3 items-center m-0;

            li {
                padding: 5px 15px;
                font-weight: 600;
                @apply cursor-pointer capitalize text-textColor text-sm;

                a {
                    font-size: 1.1rem;
                }

                &:hover {
                    a {
                        @apply text-fountainBlue;
                    }
                }
            }
        }
    }

}
