@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

ul,
p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

body {
  scroll-behavior: smooth;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  @apply text-sm;
}

.footer input,
.footer textarea {
  @apply !py-3 !px-3 focus:outline-fountainBlue rounded-sm;
}

input,
textarea {
  border-color: #c2c2c2;
  border-radius: 5px;
  width: 300px;
  padding: 12px !important;
  @apply border-[1.5px] !px-3 focus:outline-fountainBlue;
}

@media (max-width: 567px) {
  input,
  textarea {
    width: 100%;
  }
}

h1,
h2,
h3,
h4 {
  color: #595959;
}

section {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}
.text-red {
  color: #c53030;
}

.text-red {
  color: #c53030;
}

@media (min-width: 768px) {
  section {
    width: 750px;
  }
}

@media (min-width: 992px) {
  section {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  section {
    width: 1100px;
  }
}

.toaster-wrapper {
  border-radius: 4px !important;
}

.toaster-wrapper__content {
  color: #fff;
}

.toaster-wrapper_success {
  background: #62bdb5 !important;
}

.toaster-wrapper_error {
  @apply  !bg-themeBrown;
}

.Toastify__close-button {
  color: #fff !important;
  opacity: 0.7 !important;
}
