.custom-select {
	@apply w-full;

	&.error {
		.css-1s2u09g-control {
			@apply border-themeBrown;
		}
	}

	.css-1hb7zxy-IndicatorsContainer {
		.css-1okebmr-indicatorSeparator {
			@apply hidden;
		}
	}

	.css-1s2u09g-control {
   
		&:hover,
		&:focus {
			box-shadow: 0 0 10px #78ace489;
			border-color: rgb(120 173 228)
		}
	}
}

div[class*='-control'] {
	position: relative;
}