.ant-steps {
  @apply hidden;
}

.ant-drawer-content-wrapper {
  @media (max-width: 767px) {
    width: 100% !important;

    .ant-steps-vertical {
      display: none !important;
    }
  }
}

.stepper-content {
  max-height: calc(100vh - 230px);
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
  padding-bottom: 30px;
  @apply w-full;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-fountainBlue rounded-md;
}

.ant-drawer-header {
    display: none !important;

  .ant-drawer-header-title {
    justify-content: end;
  }
}

.ant-drawer-body {
  overflow-x: hidden !important;
  padding-top: 60px;
  @apply flex flex-col items-center justify-center;
}

.ant-progress {
  margin: 0 !important;

  .ant-progress-outer {
    height: 0 !important;
  }

  .ant-progress-inner {
    background-color: #f3f3f3;
  }

  .ant-progress-bg {
    background-color: #00bac7 !important;
    padding: 5px !important;
    // @apply !bg-fountainBlue;
  }
}

.box-shadow {
  box-shadow: 0 0 10px #0000002a;
}

abbr:where([title]) {
  text-decoration: none !important;
  --tw-text-opacity: 1;
  color: rgb(115 115 115);
  font-size: 9px
}

.react-calendar {
  width: 250px !important;
  border: 1px solid #dddddd;
  @apply rounded-lg pb-2;

  .react-calendar__tile {
    font-size: 10px
  }

  .react-calendar__tile--active {
    background-color: #00bac7 !important;
  }

  .react-calendar__tile {
    padding: 6px 2px;
  }

  .react-calendar__navigation {
    padding: 6px;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }
}

.ant-progress-text {
  display: none !important;
}

.error-image {
  height: 210px;
  margin: 0 auto;
}
.info-image {
  height: 200px;
}
.circle-background {
  height: 15px;
  width: 15px;
  background-color: #00bac7;
  border-radius: 50%;
  display: inline-block;
  font-size: 11px;
  line-height: 17px;
  margin-left: 4px;
  position: relative;
  top: -10px;
}

.closeModal {
  transition: all 0.5s;

  @apply border-fountainBlue border text-fountainBlue rounded-full;
  &:hover {
    transition: all 0.5s ;
    @apply bg-fountainBlue rounded-[25px] text-white;
    width: 120px;
    .content {
      opacity: 1;
      right: 0;
    }
  }
  .content {
    opacity: 0;
    right: -70px;
    transition: all 0.5s ;
    @apply text-white
  }
}
