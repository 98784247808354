.connection-list{
    li{
        &::before {
            content: '';
            background-image: url(../../assets/images/check.png);
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: center;
            top: 10px;
            border-radius: 50%;
            @apply absolute left-0 bg-fountainBlue  p-1
        }
        @apply pl-8 relative py-2;    

    }
}
