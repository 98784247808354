.right-bg {
  max-width: 320px;

  @media (min-width: 1024px) {
    max-width: 420px;
  }

  @apply relative pb-6 lg:pb-0;

  &::before {
    content: "";
    animation: change-background-color 8s infinite;
    transform: rotate(-3deg);
    left: -3px;
    width: 100%;
    height: 82%;
    z-index: -1;
    @apply absolute bg-fountainBlue bottom-4;
  }
}

@keyframes change-background-color {
  0% {
    @apply bg-fountainBlue;
    filter: blur(1px);
  }

  33% {
    transform: rotate(0deg);
    height: 90%;
    filter: blur(-1px);
    @apply bg-green;
  }

  66% {
    transform: rotate(3deg);
    @apply bg-yellowGreen;
    filter: blur(-1px);

  }

  100% {
    filter: blur(-1px);
    @apply bg-fountainBlue;
  }
}
