.footer {
    box-shadow: 0 0 10px #0000002a;
    @apply bg-white justify-between;


    .logo {
        max-width: 340px;

        img {
            max-width: 100%;
        }
    }

    .right-nav {
        align-items: center;
        justify-content: flex-end;

        .navigation-list {
            gap: 10px;
            list-style: none;
            align-items: center;
            margin: 0;

            li {
                padding: 5px 10px;
            }
        }
    }

}
