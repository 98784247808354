.ant-card {
    box-shadow: 0 0 10px #0000002a;
    border-radius: 8px;
    @apply relative p-2 ;

    .ant-card-body {
        padding: 10px !important;
    }
}

.card-animations {
    // &:hover {
        .div1 {
            animation: change-background-color1 8s infinite;
        }

        .div2 {
            animation: change-background-color2 5s infinite;
        }

        .div3 {
            animation: change-background-color3 9s infinite;
        }

        .div4 {
            animation: change-background-color4 4s infinite;
        }
    // }
}

@keyframes change-background-color1 {
    0% {
        left: -10px;
        top: -10px;
    }

    50% {
        left: 25px;
        top: -20px;
    }
}

@keyframes change-background-color2 {
    0% {
        right: -6px;
        top: -6px;
    }

    50% {
        right: -6px;
        top: 86px;
    }
}

@keyframes change-background-color3 {
    0% {
        left: 20px;
        bottom: 10px;
    }

    50% {
        left: -7px;
        bottom: 20px;
    }
}

@keyframes change-background-color4 {
    0% {
        right: -7px;
        bottom: 10px;       
    }

    50% {
        right: -7px;
        bottom: 90px;
    }
}
